import styled from "styled-components";

interface IProps {
  gap?: number;
}

export const SingleColumnLayout = styled.div<IProps>`
  margin-top: 24px;

  display: grid;
  grid-template-columns: calc(100vw - 36px);
  justify-content: center;
  gap: ${props => props.gap || 18}px;
`;
