import { IPaginationParams } from "../utils/paginationHelper";

export interface IPaginationParams {
  first?: string;
  last?: string;
  before?: string;
  after?: string;
}

export interface IForwardPagination {
  direction: PaginateDirection.forward;
  first: number;
  after?: string;
}

export interface IBackwardPagination {
  direction: PaginateDirection.backward;
  last: number;
  before?: string;
}

export enum PaginateDirection {
  forward,
  backward
}

export type IPagination = IForwardPagination | IBackwardPagination;

export const paramsFromPagination = (
  pagination: IPagination,
  initial: URLSearchParams = new URLSearchParams()
) => {
  initial.delete("first");
  initial.delete("after");
  initial.delete("last");
  initial.delete("before");

  if (pagination.direction === PaginateDirection.backward) {
    const { last, before } = pagination;

    initial.set("last", last.toString());
    if (before) {
      initial.set("before", before);
    }

    return initial;
  }

  const { first, after } = pagination;
  initial.set("first", first.toString());
  if (after) {
    initial.set("after", after);
  }

  return initial;
};

export const paginationFromParams = (
  { first, last, before, after }: IPaginationParams,
  fallback: IPagination = { first: 10, direction: PaginateDirection.forward }
): IPagination => {
  if (first) {
    return {
      direction: PaginateDirection.forward,
      first: parseInt(first, 10),
      after: after || undefined
    };
  }

  if (last) {
    return {
      direction: PaginateDirection.backward,
      last: parseInt(last, 10),
      before: before || undefined
    };
  }

  return fallback;
};

export const paginate = (
  pagination: IPagination,
  direction: PaginateDirection,
  cursor?: string
): IPagination => {
  const count =
    pagination.direction === PaginateDirection.forward
      ? pagination.first
      : pagination.last;

  if (direction === PaginateDirection.forward) {
    return { direction, first: count, after: cursor };
  }

  return { direction, last: count, before: cursor };
};
