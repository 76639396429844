import { FormGroup, InputGroup } from "@blueprintjs/core";
import React, { FC, FormEvent } from "react";
import { IProductFormState } from "../../containers/Product/ProductFormContainer";
import { IProductFragment } from "../../graphql/api";
import { FormType } from "../Form/FormType";
import { SubmitButton } from "../Form/SubmitButton";

interface IProps {
  formType: FormType;
  product: IProductFragment;
  formState: IProductFormState;
  onSubmit: () => void;
  onChange: (changes: Partial<IProductFormState>) => void;
}

export const ProductForm: FC<IProps> = ({
  formType,
  formState,
  onSubmit,
  onChange
}) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  const handleInputChange = (key: keyof IProductFormState) => (
    event: FormEvent<HTMLInputElement>
  ) => {
    onChange({
      [key]: event.currentTarget.value
    });
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <FormGroup label="Title">
        <InputGroup
          onChange={handleInputChange("title")}
          value={formState.title}
        />
      </FormGroup>

      <SubmitButton formType={formType} subject="product" />
    </form>
  );
};
