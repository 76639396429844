import { matchPath } from "react-router";

interface IParams {
  shopHandle: string;
}

export const shopHandleFromUrl = (url: string = document.location.pathname) => {
  const match = matchPath<IParams>(url, {
    path: "/stores/:shopHandle/"
  });

  return match ? match.params.shopHandle : null;
};
