import * as colors from "@material-ui/core/colors";
import { rgba } from "polished";
import { shadows } from "./shadows";
import { ZIndex } from "./ZIndex";

export const textColors = {
  light: {
    primary: rgba("#000", 0.87),
    secondary: rgba("#000", 0.54),
    hint: rgba("#000", 0.38)
  },
  dark: {
    primary: "#fff",
    secondary: rgba("#fff", 0.7),
    hint: rgba("#fff", 0.5)
  }
};

export const brandColors = {
  primary: "#1199EE",
  secondary: colors.blueGrey[500]
};

export const iconColors = {
  light: {
    active: textColors.light.secondary,
    inactive: textColors.light.hint
  },
  dark: {
    active: textColors.dark.primary,
    inactive: textColors.dark.hint
  }
};

export const dividerColors = {
  light: rgba("#000", 0.12),
  dark: rgba("#fff", 0.12)
};

export const easingCurves = {
  standard: "cubic-bezier(.4, .0, .2, 1)",
  decelaration: "cubic-bezier(.0, .0, .2, 1)",
  acceleration: "cubic-bezier(.4, .0, 1, 1)",
  sharp: "cubic-bezier(.4, .0, .6, 1)"
};

export const theme = {
  ZIndex,
  colors,
  shadows,
  brandColors,
  textColors,
  iconColors,
  dividerColors,
  easingCurves
};

export interface IAdminTheme {
  ZIndex: typeof theme.ZIndex;
  colors: typeof theme.colors;
  shadows: typeof theme.shadows;
  brandColors: typeof theme.brandColors;
  textColors: typeof theme.textColors;
  iconColors: typeof theme.iconColors;
  dividerColors: typeof theme.dividerColors;
  easingCurves: typeof theme.easingCurves;
}
