import { Button, IButtonProps } from "@blueprintjs/core";
import React, { FC } from "react";
import { FormType } from "./FormType";

interface IProps {
  formType: FormType;
  subject?: string;
}

export const SubmitButton: FC<
  IProps & React.ButtonHTMLAttributes<HTMLButtonElement> & IButtonProps
> = ({ formType, subject, ...props }) => {
  const template = (verb: string) => (subject ? `${verb} ${subject}` : verb);

  switch (formType) {
    case FormType.CREATE:
      return (
        <Button type="submit" intent="primary" {...props}>
          {template("Create")}
        </Button>
      );

    case FormType.UPDATE:
      return (
        <Button type="submit" intent="primary" {...props}>
          {template("Update")}
        </Button>
      );

    case FormType.DELETE:
      return (
        <Button type="submit" intent="danger" {...props}>
          {template("Delete")}
        </Button>
      );
  }
};
