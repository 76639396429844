import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { Loader } from "../../components/Common/Loader";
import { ApolloQueryError } from "../../components/Error/ApolloQueryError";
import { UnexpectedError } from "../../components/Error/UnexpectedError";
import { FormType } from "../../components/Form/FormType";
import {
  IProductFormState,
  ProductFormContainer
} from "../../containers/Product/ProductFormContainer";
import { useProductQuery, useUpdateProductMutation } from "../../graphql/api";
import { SingleColumnLayout } from "../../layouts/SingleColumnLayout";

interface IParams {
  id: string;
}

export const ProductPage: FC<RouteComponentProps<IParams>> = ({
  match: { params }
}) => {
  const { id } = params;
  const { loading, error, data } = useProductQuery({ variables: { id } });
  const updateProduct = useUpdateProductMutation();

  const handleSubmit = ({ title }: IProductFormState) => {
    updateProduct({
      variables: {
        input: {
          productId: id,
          title
        }
      }
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ApolloQueryError error={error} />;
  }

  if (!data || !data.product) {
    return <UnexpectedError />;
  }

  return (
    <SingleColumnLayout>
      <ProductFormContainer
        formType={FormType.UPDATE}
        onSubmit={handleSubmit}
        product={data.product}
      />
    </SingleColumnLayout>
  );
};
