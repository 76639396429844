import { setContext } from "apollo-link-context";

export const authLink = setContext((operation, { headers }) => {
  const sessionToken = localStorage.getItem("session_token");

  return {
    headers: {
      ...headers,
      authorization: sessionToken ? `Bearer ${sessionToken}` : ""
    }
  };
});
