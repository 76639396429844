import React, { FC } from "react";
import styled from "styled-components";
import { PrimaryNavigation } from "../components/Navigation/PrimaryNavigation";

const MainWrapper = styled.main`
  min-height: 100vh;
`;

export const MainLayout: FC = ({ children }) => {
  return (
    <MainWrapper>
      <PrimaryNavigation />
      {children}
    </MainWrapper>
  );
};
