import React, { FC, useEffect, useState } from "react";
import { FormType } from "../../components/Form/FormType";
import { ProductForm } from "../../components/Product/ProductForm";
import { IProductFragment } from "../../graphql/api";

export interface IProductFormState {
  title: string;
  price: string;
}

interface IProps {
  formType: FormType;
  product: IProductFragment;
  onSubmit: (formState: IProductFormState) => void;
}

export const ProductFormContainer: FC<IProps> = ({
  formType,
  product,
  onSubmit
}) => {
  const [formState, setFormState] = useState<IProductFormState>({
    title: "",
    price: "0.00"
  });

  // Update the formState if the initial product changes
  useEffect(() => setFormState(product), [product]);

  const handleSubmit = () => {
    onSubmit(formState);
  };

  const handleChange = (changes: Partial<IProductFormState>) => {
    setFormState({ ...formState, ...changes });
  };

  return (
    <ProductForm
      formState={formState}
      formType={formType}
      product={product}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};
