/* eslint-disable */
import gql from "graphql-tag";
import * as ReactApolloHooks from "react-apollo-hooks";
import * as ReactApollo from "react-apollo";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC and any UTC offset other than 0 will be rejected.
   */
  DateTime: any;
  /** The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: any;
  Currency: any;
  Locale: any;
  /** The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library.  The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: any;
};

export type IAccessPass = INode & {
  __typename?: "AccessPass";
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  permissions: Array<Scalars["String"]>;
  shop: IShop;
  updatedAt: Scalars["NaiveDateTime"];
  user: IUser;
};

export type IAccessPassConnection = {
  __typename?: "AccessPassConnection";
  edges: Array<IAccessPassEdge>;
  pageInfo: IPageInfo;
};

export type IAccessPassEdge = {
  __typename?: "AccessPassEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node: IAccessPass;
};

export type IAccessPassFilter = {
  userId: Scalars["ID"];
};

export type IAccessPassSortOrder = {
  insertedAt?: Maybe<ISortOrder>;
  updatedAt?: Maybe<ISortOrder>;
};

export type IAddress = {
  __typename?: "Address";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  provinceCode?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type IAddressInput = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  provinceCode?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type ICollection = INode & {
  __typename?: "Collection";
  collectionTranslations: Array<ICollectionTranslation>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  products?: Maybe<IProductConnection>;
  shop: IShop;
  title: Scalars["String"];
  updatedAt: Scalars["NaiveDateTime"];
};

export type ICollectionProductsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filter?: Maybe<IProductFilter>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  order?: Maybe<IProductSortOrder>;
};

export type ICollectionConnection = {
  __typename?: "CollectionConnection";
  edges: Array<ICollectionEdge>;
  pageInfo: IPageInfo;
};

export type ICollectionEdge = {
  __typename?: "CollectionEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node: ICollection;
};

export type ICollectionFilter = {
  title?: Maybe<Scalars["String"]>;
};

export type ICollectionSortOrder = {
  insertedAt?: Maybe<ISortOrder>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<ISortOrder>;
};

export type ICollectionTranslation = INode & {
  __typename?: "CollectionTranslation";
  /** The ID of an object */
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
};

export type ICreateAccessPassInput = {
  expiresAt?: Maybe<Scalars["DateTime"]>;
  permissions: Array<Maybe<Scalars["String"]>>;
  shopId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type ICreateAccessPassPayload = {
  __typename?: "CreateAccessPassPayload";
  accessPass: IAccessPass;
};

export type ICreateCollectionInput = {
  title: Scalars["String"];
};

export type ICreateCollectionPayload = {
  __typename?: "CreateCollectionPayload";
  collection: ICollection;
};

export type ICreateOrderInput = {
  billingAddress: IAddressInput;
  email: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  orderItems: Array<IOrderItemInput>;
  phone?: Maybe<Scalars["String"]>;
  shippingAddress: IAddressInput;
};

export type ICreateOrderPayload = {
  __typename?: "CreateOrderPayload";
  order: IOrder;
};

/** CreateProductInput */
export type ICreateProductInput = {
  compareAtPrice?: Maybe<Scalars["Decimal"]>;
  description?: Maybe<Scalars["String"]>;
  price: Scalars["Decimal"];
  productOptions?: Maybe<Array<Maybe<IProductOptionInput>>>;
  title: Scalars["String"];
};

export type ICreateProductPayload = {
  __typename?: "CreateProductPayload";
  product: IProduct;
};

export type ICreateProductVariantInput = {
  barcode?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  price: Scalars["Decimal"];
  productVariantId: Scalars["ID"];
  sku: Scalars["String"];
  title: Scalars["String"];
};

export type ICreateProductVariantPayload = {
  __typename?: "CreateProductVariantPayload";
  productVariant: IProductVariant;
};

export type ICreateSessionInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type ICreateSessionPayload = {
  __typename?: "CreateSessionPayload";
  session: ISession;
};

export type ICreateShopInput = {
  currency: Scalars["String"];
  handle: Scalars["String"];
  name: Scalars["String"];
};

export type ICreateShopPayload = {
  __typename?: "CreateShopPayload";
  shop: IShop;
};

export type ICreateUserInput = {
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
};

export type ICreateUserPayload = {
  __typename?: "CreateUserPayload";
  user: IUser;
};

export type IDeleteAccessPassInput = {
  accessPassId: Scalars["ID"];
};

export type IDeleteAccessPassPayload = {
  __typename?: "DeleteAccessPassPayload";
  accessPass: IAccessPass;
};

export type IDeleteCollectionInput = {
  collectionId: Scalars["ID"];
};

export type IDeleteCollectionPayload = {
  __typename?: "DeleteCollectionPayload";
  collection: ICollection;
};

export type IDeleteOrderInput = {
  orderId: Scalars["ID"];
};

export type IDeleteOrderPayload = {
  __typename?: "DeleteOrderPayload";
  order: IOrder;
};

export type IDeleteProductInput = {
  productId: Scalars["ID"];
};

export type IDeleteProductPayload = {
  __typename?: "DeleteProductPayload";
  product: IProduct;
};

export type IDeleteProductVariantInput = {
  productVariantId: Scalars["ID"];
};

export type IDeleteProductVariantPayload = {
  __typename?: "DeleteProductVariantPayload";
  productVariant: IProductVariant;
};

export type IDeleteShopPayload = {
  __typename?: "DeleteShopPayload";
  shop: IShop;
};

export type IDeleteUserInput = {
  userId: Scalars["ID"];
};

export type IDeleteUserPayload = {
  __typename?: "DeleteUserPayload";
  user: IUser;
};

export type IMedia = {
  __typename?: "Media";
  alt?: Maybe<Scalars["String"]>;
  insertedAt: Scalars["NaiveDateTime"];
  poster?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["NaiveDateTime"];
  url?: Maybe<Scalars["String"]>;
};

/** An object with an ID */
export type INode = {
  /** The id of the object. */
  id: Scalars["ID"];
};

export type IOrder = INode & {
  __typename?: "Order";
  billingAddress: IAddress;
  currency: Scalars["String"];
  email: Scalars["String"];
  financialStatus: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  name: Scalars["String"];
  orderItems: Array<IOrderItem>;
  phone?: Maybe<Scalars["String"]>;
  shippingAddress: IAddress;
  status: Scalars["String"];
  subtotalPrice: Scalars["Decimal"];
  token: Scalars["String"];
  totalPrice: Scalars["Decimal"];
  updatedAt: Scalars["NaiveDateTime"];
};

export type IOrderConnection = {
  __typename?: "OrderConnection";
  edges?: Maybe<Array<Maybe<IOrderEdge>>>;
  pageInfo: IPageInfo;
};

export type IOrderEdge = {
  __typename?: "OrderEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<IOrder>;
};

export type IOrderFilter = {
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type IOrderItem = {
  __typename?: "OrderItem";
  barcode?: Maybe<Scalars["String"]>;
  price: Scalars["String"];
  quantity: Scalars["Int"];
  sku: Scalars["String"];
  title: Scalars["String"];
};

export type IOrderItemInput = {
  quantity: Scalars["Int"];
  sku: Scalars["String"];
};

export type IOrderSortOrder = {
  email?: Maybe<ISortOrder>;
  insertedAt?: Maybe<ISortOrder>;
  name?: Maybe<ISortOrder>;
  updatedAt?: Maybe<ISortOrder>;
};

export type IPageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type IPrice = {
  __typename?: "Price";
  compareAtPrice?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  insertedAt: Scalars["NaiveDateTime"];
  price?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["NaiveDateTime"];
};

/** Product */
export type IProduct = INode & {
  __typename?: "Product";
  /** Collections */
  collections: Array<ICollection>;
  compareAtPrice?: Maybe<Scalars["Decimal"]>;
  description?: Maybe<Scalars["String"]>;
  /** DisplayPrice */
  displayPrice?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  /** Media */
  media: Array<IMedia>;
  price: Scalars["Decimal"];
  /** Prices */
  prices?: Maybe<Array<Maybe<IPrice>>>;
  productOptions?: Maybe<Array<Maybe<IProductOption>>>;
  /** ProductTranslations */
  productTranslations: Array<IProductTranslation>;
  /** ProductVariants */
  productVariants: Array<IProductVariant>;
  /** Shop */
  shop: IShop;
  title: Scalars["String"];
  updatedAt: Scalars["NaiveDateTime"];
};

/** Product */
export type IProductProductVariantsArgs = {
  filter?: Maybe<IProductVariantFilter>;
  locale?: Maybe<Scalars["Locale"]>;
  order?: Maybe<IProductVariantSortOrder>;
};

export type IProductConnection = {
  __typename?: "ProductConnection";
  edges: Array<IProductEdge>;
  pageInfo: IPageInfo;
};

export type IProductEdge = {
  __typename?: "ProductEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node: IProduct;
};

/** ProductFilter */
export type IProductFilter = {
  title?: Maybe<Scalars["String"]>;
};

/** ProductOption */
export type IProductOption = {
  __typename?: "ProductOption";
  name: Scalars["String"];
  position?: Maybe<Scalars["Int"]>;
  values: Array<Scalars["String"]>;
};

/** ProductOptionInput */
export type IProductOptionInput = {
  name: Scalars["String"];
  position?: Maybe<Scalars["Int"]>;
  values: Array<Scalars["String"]>;
};

/** ProductSortOrder */
export type IProductSortOrder = {
  insertedAt?: Maybe<ISortOrder>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<ISortOrder>;
};

/** ProductTranslation */
export type IProductTranslation = INode & {
  __typename?: "ProductTranslation";
  description?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
};

export type IProductVariant = INode & {
  __typename?: "ProductVariant";
  barcode?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  displayPrice?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  price: Scalars["Decimal"];
  productVariantOptions: Array<IProductVariantOption>;
  productVariantTranslations: Array<IProductVariantTranslation>;
  sku: Scalars["String"];
  title: Scalars["String"];
  updatedAt: Scalars["NaiveDateTime"];
};

export type IProductVariantConnection = {
  __typename?: "ProductVariantConnection";
  edges: Array<IProductVariantEdge>;
  pageInfo: IPageInfo;
};

export type IProductVariantEdge = {
  __typename?: "ProductVariantEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node: IProductVariant;
};

export type IProductVariantFilter = {
  barcode?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type IProductVariantOption = {
  __typename?: "ProductVariantOption";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type IProductVariantSortOrder = {
  insertedAt?: Maybe<ISortOrder>;
  title?: Maybe<ISortOrder>;
  updatedAt?: Maybe<ISortOrder>;
};

export type IProductVariantTranslation = INode & {
  __typename?: "ProductVariantTranslation";
  description?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
};

export type IRootMutationType = {
  __typename?: "RootMutationType";
  /** Create a new AccessPass */
  createAccessPass?: Maybe<ICreateAccessPassPayload>;
  /** Create a new Collection */
  createCollection?: Maybe<ICreateCollectionPayload>;
  /** Create a new Order */
  createOrder?: Maybe<ICreateOrderPayload>;
  /** Create a new Product */
  createProduct?: Maybe<ICreateProductPayload>;
  /** Create a new ProductVariant */
  createProductVariant?: Maybe<ICreateProductVariantPayload>;
  /** Create a new Session */
  createSession?: Maybe<ICreateSessionPayload>;
  /** Create a new Shop */
  createShop?: Maybe<ICreateShopPayload>;
  /** Create a new User */
  createUser?: Maybe<ICreateUserPayload>;
  /** Delete an existing AccessPass */
  deleteAccessPass?: Maybe<IDeleteAccessPassPayload>;
  /** Delete an existing Collection */
  deleteCollection?: Maybe<IDeleteCollectionPayload>;
  /** Delete an existing Order */
  deleteOrder?: Maybe<IDeleteOrderPayload>;
  /** Delete an existing Product */
  deleteProduct?: Maybe<IDeleteProductPayload>;
  /** Delete an existing ProductVariant */
  deleteProductVariant?: Maybe<IDeleteProductVariantPayload>;
  /** Delete an existing Shop */
  deleteShop?: Maybe<IDeleteShopPayload>;
  /** Delete an existing User */
  deleteUser?: Maybe<IDeleteUserPayload>;
  /** Update an existing AccessPass */
  updateAccessPass?: Maybe<IUpdateAccessPassPayload>;
  /** Update an existing Collection */
  updateCollection?: Maybe<IUpdateCollectionPayload>;
  /** Update an existing Order */
  updateOrder?: Maybe<IUpdateOrderPayload>;
  /** Update an existing Product */
  updateProduct?: Maybe<IUpdateProductPayload>;
  /** Update an existing ProductVariant */
  updateProductVariant?: Maybe<IUpdateProductVariantPayload>;
  /** Update an existing Shop */
  updateShop?: Maybe<IUpdateShopPayload>;
  /** Update an existing User */
  updateUser?: Maybe<IUpdateUserPayload>;
};

export type IRootMutationTypeCreateAccessPassArgs = {
  input: ICreateAccessPassInput;
};

export type IRootMutationTypeCreateCollectionArgs = {
  input: ICreateCollectionInput;
};

export type IRootMutationTypeCreateOrderArgs = {
  input: ICreateOrderInput;
};

export type IRootMutationTypeCreateProductArgs = {
  input: ICreateProductInput;
};

export type IRootMutationTypeCreateProductVariantArgs = {
  input: ICreateProductVariantInput;
};

export type IRootMutationTypeCreateSessionArgs = {
  input: ICreateSessionInput;
};

export type IRootMutationTypeCreateShopArgs = {
  input: ICreateShopInput;
};

export type IRootMutationTypeCreateUserArgs = {
  input: ICreateUserInput;
};

export type IRootMutationTypeDeleteAccessPassArgs = {
  input: IDeleteAccessPassInput;
};

export type IRootMutationTypeDeleteCollectionArgs = {
  input: IDeleteCollectionInput;
};

export type IRootMutationTypeDeleteOrderArgs = {
  input: IDeleteOrderInput;
};

export type IRootMutationTypeDeleteProductArgs = {
  input: IDeleteProductInput;
};

export type IRootMutationTypeDeleteProductVariantArgs = {
  input: IDeleteProductVariantInput;
};

export type IRootMutationTypeDeleteUserArgs = {
  input: IDeleteUserInput;
};

export type IRootMutationTypeUpdateAccessPassArgs = {
  input: IUpdateAccessPassInput;
};

export type IRootMutationTypeUpdateCollectionArgs = {
  input: IUpdateCollectionInput;
};

export type IRootMutationTypeUpdateOrderArgs = {
  input: IUpdateOrderInput;
};

export type IRootMutationTypeUpdateProductArgs = {
  input: IUpdateProductInput;
};

export type IRootMutationTypeUpdateProductVariantArgs = {
  input: IUpdateProductVariantInput;
};

export type IRootMutationTypeUpdateShopArgs = {
  input: IUpdateShopInput;
};

export type IRootMutationTypeUpdateUserArgs = {
  input: IUpdateUserInput;
};

export type IRootQueryType = {
  __typename?: "RootQueryType";
  /** Fetch all Accounts */
  accessPassesByUser?: Maybe<IAccessPassConnection>;
  /** Fetch all Catalogs */
  collectionsByShop?: Maybe<ICollectionConnection>;
  /** Get logged in User */
  currentUser?: Maybe<IUser>;
  /** Fetches an object given its ID */
  node?: Maybe<INode>;
  /** Fetch all Sales */
  orders?: Maybe<IOrderConnection>;
  /** Fetch all Catalogs */
  productVariantsByProduct?: Maybe<IProductVariantConnection>;
  /** Fetch all Catalogs */
  productsByShop?: Maybe<IProductConnection>;
  /** Fetch all Accounts */
  shops?: Maybe<IShopConnection>;
};

export type IRootQueryTypeAccessPassesByUserArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filter?: Maybe<IAccessPassFilter>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  order?: Maybe<IAccessPassSortOrder>;
};

export type IRootQueryTypeCollectionsByShopArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filter?: Maybe<ICollectionFilter>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  locale?: Maybe<Scalars["Locale"]>;
  order?: Maybe<ICollectionSortOrder>;
};

export type IRootQueryTypeNodeArgs = {
  id: Scalars["ID"];
};

export type IRootQueryTypeOrdersArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filter?: Maybe<IOrderFilter>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  order?: Maybe<IOrderSortOrder>;
};

export type IRootQueryTypeProductVariantsByProductArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filter?: Maybe<IProductVariantFilter>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  order?: Maybe<IProductVariantSortOrder>;
  productId: Scalars["ID"];
};

export type IRootQueryTypeProductsByShopArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filter?: Maybe<IProductFilter>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  locale?: Maybe<Scalars["Locale"]>;
  order?: Maybe<IProductSortOrder>;
};

export type IRootQueryTypeShopsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filter?: Maybe<IShopFilter>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  order?: Maybe<IShopSortOrder>;
};

export type ISession = INode & {
  __typename?: "Session";
  expiresAt?: Maybe<Scalars["NaiveDateTime"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  token: Scalars["String"];
  updatedAt: Scalars["NaiveDateTime"];
  user: IUser;
};

export type IShop = INode & {
  __typename?: "Shop";
  currency: Scalars["Currency"];
  handle: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  name: Scalars["String"];
  updatedAt: Scalars["NaiveDateTime"];
};

export type IShopConnection = {
  __typename?: "ShopConnection";
  edges: Array<IShopEdge>;
  pageInfo: IPageInfo;
};

export type IShopEdge = {
  __typename?: "ShopEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node: IShop;
};

export type IShopFilter = {
  name?: Maybe<Scalars["String"]>;
};

export type IShopSortOrder = {
  insertedAt?: Maybe<ISortOrder>;
  name?: Maybe<ISortOrder>;
  updatedAt?: Maybe<ISortOrder>;
};

export enum ISortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export type IUpdateAccessPassInput = {
  accessPassId: Scalars["ID"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  permissions: Array<Maybe<Scalars["String"]>>;
  shopId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type IUpdateAccessPassPayload = {
  __typename?: "UpdateAccessPassPayload";
  accessPass: IAccessPass;
};

export type IUpdateCollectionInput = {
  collectionId: Scalars["ID"];
  title: Scalars["String"];
};

export type IUpdateCollectionPayload = {
  __typename?: "UpdateCollectionPayload";
  collection: ICollection;
};

export type IUpdateOrderInput = {
  billingAddress: IAddressInput;
  email: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  orderId: Scalars["ID"];
  orderItems: Array<IOrderItemInput>;
  phone?: Maybe<Scalars["String"]>;
  shippingAddress: IAddressInput;
};

export type IUpdateOrderPayload = {
  __typename?: "UpdateOrderPayload";
  order: IOrder;
};

/** UpdateProductInput */
export type IUpdateProductInput = {
  compareAtPrice?: Maybe<Scalars["Decimal"]>;
  description?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  productId: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
};

export type IUpdateProductPayload = {
  __typename?: "UpdateProductPayload";
  product: IProduct;
};

export type IUpdateProductVariantInput = {
  barcode?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  price: Scalars["Decimal"];
  productVariantId: Scalars["ID"];
  sku: Scalars["String"];
  title: Scalars["String"];
};

export type IUpdateProductVariantPayload = {
  __typename?: "UpdateProductVariantPayload";
  productVariant: IProductVariant;
};

export type IUpdateShopInput = {
  currency: Scalars["String"];
  handle: Scalars["String"];
  name: Scalars["String"];
};

export type IUpdateShopPayload = {
  __typename?: "UpdateShopPayload";
  shop: IShop;
};

export type IUpdateUserInput = {
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  userId: Scalars["ID"];
};

export type IUpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  user: IUser;
};

export type IUser = INode & {
  __typename?: "User";
  accessPasses: Array<IAccessPass>;
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["NaiveDateTime"];
  lastName?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["NaiveDateTime"];
};
export type IAccessPassWithShopFragment = { __typename?: "AccessPass" } & Pick<
  IAccessPass,
  "permissions" | "expiresAt"
> & { shop: { __typename?: "Shop" } & IShopFragment } & INodeInfoFragment;

export type IAccessPassesQueryVariables = {
  first: Scalars["Int"];
  after?: Maybe<Scalars["String"]>;
};

export type IAccessPassesQuery = { __typename?: "RootQueryType" } & {
  accessPassesByUser: Maybe<
    { __typename?: "AccessPassConnection" } & {
      pageInfo: { __typename?: "PageInfo" } & IPageInfoFragment;
      edges: Array<
        { __typename?: "AccessPassEdge" } & Pick<IAccessPassEdge, "cursor"> & {
            node: { __typename?: "AccessPass" } & IAccessPassWithShopFragment;
          }
      >;
    }
  >;
};

export type ISessionWithUserFragment = { __typename?: "Session" } & Pick<
  ISession,
  "token"
> & { user: { __typename?: "User" } & IUserFragment };

export type ICreateSessionMutationVariables = {
  input: ICreateSessionInput;
};

export type ICreateSessionMutation = { __typename?: "RootMutationType" } & {
  createSession: Maybe<
    { __typename?: "CreateSessionPayload" } & {
      session: { __typename?: "Session" } & (INodeInfoFragment &
        ISessionWithUserFragment);
    }
  >;
};

export type INodeInfoFragment = {
  __typename?:
    | "AccessPass"
    | "Shop"
    | "User"
    | "Collection"
    | "CollectionTranslation"
    | "Product"
    | "ProductTranslation"
    | "ProductVariant"
    | "ProductVariantTranslation"
    | "Order"
    | "Session";
} & Pick<INode, "id">;

export type IUserTimestampsFragment = { __typename?: "User" } & Pick<
  IUser,
  "insertedAt" | "updatedAt"
>;

export type IShopTimestampsFragment = { __typename?: "Shop" } & Pick<
  IShop,
  "insertedAt" | "updatedAt"
>;

export type IPageInfoFragment = { __typename?: "PageInfo" } & Pick<
  IPageInfo,
  "startCursor" | "endCursor" | "hasPreviousPage" | "hasNextPage"
>;

export type IProductTimestampsFragment = { __typename?: "Product" } & Pick<
  IProduct,
  "insertedAt" | "updatedAt"
>;

export type IProductFragment = { __typename?: "Product" } & Pick<
  IProduct,
  "title" | "price" | "displayPrice"
> &
  (INodeInfoFragment & IProductTimestampsFragment);

export type IProductsByShopQueryVariables = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type IProductsByShopQuery = { __typename?: "RootQueryType" } & {
  productsByShop: Maybe<
    { __typename?: "ProductConnection" } & {
      pageInfo: { __typename?: "PageInfo" } & IPageInfoFragment;
      edges: Array<
        { __typename?: "ProductEdge" } & Pick<IProductEdge, "cursor"> & {
            node: { __typename?: "Product" } & IProductFragment;
          }
      >;
    }
  >;
};

export type IProductQueryVariables = {
  id: Scalars["ID"];
};

export type IProductQuery = { __typename?: "RootQueryType" } & {
  product: Maybe<
    {
      __typename?:
        | "AccessPass"
        | "Shop"
        | "User"
        | "Collection"
        | "CollectionTranslation"
        | "Product"
        | "ProductTranslation"
        | "ProductVariant"
        | "ProductVariantTranslation"
        | "Order"
        | "Session";
    } & Pick<INode, "id"> &
      IProductFragment
  >;
};

export type IUpdateProductMutationVariables = {
  input: IUpdateProductInput;
};

export type IUpdateProductMutation = { __typename?: "RootMutationType" } & {
  updateProduct: Maybe<
    { __typename?: "UpdateProductPayload" } & {
      product: { __typename?: "Product" } & IProductFragment;
    }
  >;
};

export type ICreateProductMutationVariables = {
  input: ICreateProductInput;
};

export type ICreateProductMutation = { __typename?: "RootMutationType" } & {
  createProduct: Maybe<
    { __typename?: "CreateProductPayload" } & {
      product: { __typename?: "Product" } & IProductFragment;
    }
  >;
};

export type IShopFragment = { __typename?: "Shop" } & Pick<
  IShop,
  "currency" | "name" | "handle"
> &
  (INodeInfoFragment & IShopTimestampsFragment);

export type IUserFragment = { __typename?: "User" } & Pick<
  IUser,
  "firstName" | "lastName"
> &
  (INodeInfoFragment & IUserTimestampsFragment);

export type ICurrentUserQueryVariables = {};

export type ICurrentUserQuery = { __typename?: "RootQueryType" } & {
  currentUser: Maybe<{ __typename?: "User" } & IUserFragment>;
};
export const NodeInfoFragmentDoc = gql`
  fragment NodeInfo on Node {
    id
  }
`;
export const ShopTimestampsFragmentDoc = gql`
  fragment ShopTimestamps on Shop {
    insertedAt
    updatedAt
  }
`;
export const ShopFragmentDoc = gql`
  fragment Shop on Shop {
    ...NodeInfo
    ...ShopTimestamps
    currency
    name
    handle
  }
  ${NodeInfoFragmentDoc}
  ${ShopTimestampsFragmentDoc}
`;
export const AccessPassWithShopFragmentDoc = gql`
  fragment AccessPassWithShop on AccessPass {
    ...NodeInfo
    permissions
    expiresAt
    shop {
      ...Shop
    }
  }
  ${NodeInfoFragmentDoc}
  ${ShopFragmentDoc}
`;
export const UserTimestampsFragmentDoc = gql`
  fragment UserTimestamps on User {
    insertedAt
    updatedAt
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    ...NodeInfo
    firstName
    lastName
    ...UserTimestamps
  }
  ${NodeInfoFragmentDoc}
  ${UserTimestampsFragmentDoc}
`;
export const SessionWithUserFragmentDoc = gql`
  fragment SessionWithUser on Session {
    token
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
export const ProductTimestampsFragmentDoc = gql`
  fragment ProductTimestamps on Product {
    insertedAt
    updatedAt
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    ...NodeInfo
    ...ProductTimestamps
    title
    price
    displayPrice
  }
  ${NodeInfoFragmentDoc}
  ${ProductTimestampsFragmentDoc}
`;
export const AccessPassesDocument = gql`
  query AccessPasses($first: Int!, $after: String) {
    accessPassesByUser(first: $first, after: $after) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...AccessPassWithShop
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${AccessPassWithShopFragmentDoc}
`;

export function useAccessPassesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IAccessPassesQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    IAccessPassesQuery,
    IAccessPassesQueryVariables
  >(AccessPassesDocument, baseOptions);
}
export const CreateSessionDocument = gql`
  mutation CreateSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      session {
        ...NodeInfo
        ...SessionWithUser
      }
    }
  }
  ${NodeInfoFragmentDoc}
  ${SessionWithUserFragmentDoc}
`;
export type ICreateSessionMutationFn = ReactApollo.MutationFn<
  ICreateSessionMutation,
  ICreateSessionMutationVariables
>;

export function useCreateSessionMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    ICreateSessionMutation,
    ICreateSessionMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    ICreateSessionMutation,
    ICreateSessionMutationVariables
  >(CreateSessionDocument, baseOptions);
}
export const ProductsByShopDocument = gql`
  query ProductsByShop(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    productsByShop(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...Product
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${ProductFragmentDoc}
`;

export function useProductsByShopQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IProductsByShopQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    IProductsByShopQuery,
    IProductsByShopQueryVariables
  >(ProductsByShopDocument, baseOptions);
}
export const ProductDocument = gql`
  query Product($id: ID!) {
    product: node(id: $id) {
      id
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

export function useProductQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<IProductQueryVariables>
) {
  return ReactApolloHooks.useQuery<IProductQuery, IProductQueryVariables>(
    ProductDocument,
    baseOptions
  );
}
export const UpdateProductDocument = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        ...Product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type IUpdateProductMutationFn = ReactApollo.MutationFn<
  IUpdateProductMutation,
  IUpdateProductMutationVariables
>;

export function useUpdateProductMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    IUpdateProductMutation,
    IUpdateProductMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    IUpdateProductMutation,
    IUpdateProductMutationVariables
  >(UpdateProductDocument, baseOptions);
}
export const CreateProductDocument = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      product {
        ...Product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type ICreateProductMutationFn = ReactApollo.MutationFn<
  ICreateProductMutation,
  ICreateProductMutationVariables
>;

export function useCreateProductMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    ICreateProductMutation,
    ICreateProductMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    ICreateProductMutation,
    ICreateProductMutationVariables
  >(CreateProductDocument, baseOptions);
}
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

export function useCurrentUserQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<ICurrentUserQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    ICurrentUserQuery,
    ICurrentUserQueryVariables
  >(CurrentUserDocument, baseOptions);
}
