import ApolloClient from "apollo-client";
import { from } from "apollo-link";
import { cache } from "./cache";
import { authLink } from "./links/authLink";
import { authErrorLink } from "./links/errorLink";
import { httpLink } from "./links/httpLink";
import { shopLink } from "./links/shopLink";

export const client = new ApolloClient({
  cache,
  link: from([authLink, shopLink, authErrorLink, httpLink])
});
