import React, { FC } from "react";

import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { ThemeProvider } from "styled-components";

import { client } from "../graphql/client";
import { Router } from "../Router";
import { theme } from "../styled/theme";

export const Root: FC = () => {
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};
