import React, { FC } from "react";

import { ApolloError } from "apollo-client";
import { Error } from "./Error";

interface IProps {
  error?: ApolloError;
}

export const NetworkError: FC<IProps> = () => {
  return (
    <Error
      title="Network error"
      description="Something is preventing us from communicating with the server. It might be your internet connection, but if it isn't please get in touch so we can resolve it."
    />
  );
};
