export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "Node",
        possibleTypes: [
          {
            name: "AccessPass"
          },
          {
            name: "Shop"
          },
          {
            name: "User"
          },
          {
            name: "Collection"
          },
          {
            name: "CollectionTranslation"
          },
          {
            name: "Product"
          },
          {
            name: "ProductTranslation"
          },
          {
            name: "ProductVariant"
          },
          {
            name: "ProductVariantTranslation"
          },
          {
            name: "Order"
          },
          {
            name: "Session"
          }
        ]
      }
    ]
  }
};

export default result;
