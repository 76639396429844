import React, { FC } from "react";
import { FormType } from "../../components/Form/FormType";
import {
  IProductFormState,
  ProductFormContainer
} from "../../containers/Product/ProductFormContainer";
import { useCreateProductMutation } from "../../graphql/api";
import { SingleColumnLayout } from "../../layouts/SingleColumnLayout";

export const NewProductPage: FC = () => {
  const createProduct = useCreateProductMutation();

  const handleSubmit = ({ title, price }: IProductFormState) =>
    createProduct({
      variables: {
        input: {
          title,
          price
        }
      }
    });

  return (
    <SingleColumnLayout>
      <ProductFormContainer
        formType={FormType.CREATE}
        onSubmit={handleSubmit}
        product={{
          title: "",
          id: "",
          price: "0.00",
          updatedAt: null,
          insertedAt: null
        }}
      />
    </SingleColumnLayout>
  );
};
