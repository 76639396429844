import React, { Dispatch, FormEvent, FC, SetStateAction } from "react";

import { Button, Card, FormGroup, H4, InputGroup } from "@blueprintjs/core";
import { ISignInFormState } from "../../containers/Auth/SignInContainer";

interface IProps {
  onSubmit: () => void;
  isLoading: boolean;
  formState: ISignInFormState;
  setFormState: Dispatch<SetStateAction<ISignInFormState>>;
}

export const SignInForm: FC<IProps> = ({
  formState,
  setFormState,
  onSubmit
}) => {
  const { email, password } = formState;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit();
  };

  const handleEmailChange = (event: FormEvent<HTMLInputElement>) => {
    setFormState({ ...formState, email: event.currentTarget.value });
  };

  const handlePasswordChange = (event: FormEvent<HTMLInputElement>) => {
    setFormState({ ...formState, password: event.currentTarget.value });
  };

  return (
    <Card elevation={1}>
      <H4>Sign in</H4>
      <p>Please sign in to manage your store.</p>

      <form method="post" onSubmit={handleSubmit}>
        <FormGroup label="Email address" labelFor="auth-login-email">
          <InputGroup
            id="auth-login-email"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus={true}
            required={true}
            value={email}
            onChange={handleEmailChange}
          />
        </FormGroup>

        <FormGroup label="Password" labelFor="auth-login-password">
          <InputGroup
            id="auth-login-password"
            name="password"
            type="password"
            autoComplete="current-password"
            required={true}
            value={password}
            onChange={handlePasswordChange}
          />
        </FormGroup>

        <Button type="submit" intent="primary">
          Sign in
        </Button>
      </form>
    </Card>
  );
};
