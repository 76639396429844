import React, { FC } from "react";

import { IconName, MaybeElement, NonIdealState } from "@blueprintjs/core";
import { CenteredLayout } from "../../layouts/CenteredLayout";

interface IProps {
  fullPage?: boolean;
  title: React.ReactChild;
  description: React.ReactChild;
  icon?: IconName | MaybeElement;
}

export const Error: FC<IProps> = ({
  title,
  description,
  icon = "error",
  fullPage = true
}) => {
  const error = (
    <NonIdealState title={title} description={description} icon={icon} />
  );

  if (fullPage) {
    return <CenteredLayout>{error}</CenteredLayout>;
  }

  return error;
};
