import React, { FC } from "react";
import { Loader } from "../../components/Common/Loader";
import { ApolloQueryError } from "../../components/Error/ApolloQueryError";
import { UnexpectedError } from "../../components/Error/UnexpectedError";
import { ProductListContainer } from "../../containers/Product/ProductListContainer";
import { useProductsByShopQuery } from "../../graphql/api";
import { usePaginator } from "../../hooks/usePaginator";
import { SingleColumnLayout } from "../../layouts/SingleColumnLayout";

export const ProductListPage: FC = () => {
  const [pagination, actions] = usePaginator();
  const { data, error, loading } = useProductsByShopQuery({
    variables: {
      ...pagination
    }
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ApolloQueryError error={error} />;
  }

  if (!data || !data.productsByShop) {
    return <UnexpectedError />;
  }

  const products = data.productsByShop.edges.map(edge => edge.node);

  return (
    <SingleColumnLayout>
      <ProductListContainer
        products={products}
        pageInfo={data.productsByShop.pageInfo}
        actions={actions}
      />
    </SingleColumnLayout>
  );
};
