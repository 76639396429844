import React, { FC, useState } from "react";
import styled from "styled-components";
import { Logo } from "../Common/Logo";
import { StoreLink } from "../Common/StoreLink";
import { PrimaryNavigationLink } from "./PrimaryNavigationLink";

const NavbarWrapper = styled.nav`
  display: grid;
  grid-template-areas: "logo menu current-user";
  grid-auto-columns: auto 1fr auto;
  grid-auto-rows: 48px;
  align-items: center;

  height: 48px;
  padding: 0 36px;
  gap: 24px;
  border-bottom: 1px solid;

  color: ${props => props.theme.textColors.light.primary};
  border-color: ${props => props.theme.dividerColors.light};
`;

const NavbarMenu = styled.div`
  display: flex;
`;

const LinkedLogo = styled(StoreLink)``;

export const PrimaryNavigation: FC = () => {
  const [value, setValue] = useState("");
  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  return (
    <NavbarWrapper>
      <LinkedLogo to="./dashboard" aria-label="Dashboard">
        <Logo width="24" />
      </LinkedLogo>
      <NavbarMenu>
        <PrimaryNavigationLink to="./orders">Orders</PrimaryNavigationLink>
        <PrimaryNavigationLink to="./products">Products</PrimaryNavigationLink>
        <PrimaryNavigationLink to="./insights">Insights</PrimaryNavigationLink>
      </NavbarMenu>
      <input type="text" value={value} onChange={handleInputChange} />
    </NavbarWrapper>
  );
};
