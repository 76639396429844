import React, { FC } from "react";
import { Error } from "./Error";

export const InternetConnectionError: FC = () => {
  return (
    <Error
      title="Internet offline"
      description="Your internet connection seems to be offline. Please check your internet connection and try again."
    />
  );
};
