import { setContext } from "apollo-link-context";
import { shopHandleFromUrl } from "../../utils/shopHandleFromUrl";

export const shopLink = setContext((operation, { headers }) => {
  const shopHandle = shopHandleFromUrl();

  return {
    headers: {
      ...headers,
      "x-novistore-shop-handle": shopHandle
    }
  };
});
