import React, { FC, ReactChild, useEffect, useState } from "react";

import { NonIdealState, Spinner } from "@blueprintjs/core";

interface IProps {
  title?: ReactChild;
  description?: ReactChild;

  errorTitle?: ReactChild;
  errorDescription?: ReactChild;
  timeout?: number;
  delay?: number | boolean;
}

export const Loader: FC<IProps> = ({
  delay = 200,
  timeout = 5000,
  title = "Loading...",
  description,
  errorTitle = "This is taking longer than expected",
  errorDescription = "Sometimes, refreshing the page could help, but if you keep seeing this message, please get in touch with us"
}) => {
  const [timedOut, setTimedOut] = useState(false);
  const [showLoader, setShowLoader] = useState(delay ? false : true);

  useEffect(() => {
    if (delay) {
      const timer = setTimeout(() => setShowLoader(true), delay);

      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => setTimedOut(true), timeout);

    return () => clearTimeout(timer);
  });

  // Show an time out message when we've waited too long
  if (timedOut) {
    return (
      <NonIdealState
        title={errorTitle}
        description={errorDescription}
        icon="error"
      />
    );
  }

  if (!showLoader) {
    return null;
  }

  return (
    <NonIdealState title={title} description={description} icon={<Spinner />} />
  );
};
