import React, { FC } from "react";

import { AccessPassSelector } from "../../components/AccessPass/AccessPassSelector";
import { Loader } from "../../components/Common/Loader";
import { ApolloQueryError } from "../../components/Error/ApolloQueryError";
import { Error } from "../../components/Error/Error";
import {
  IAccessPassesQuery,
  IAccessPassWithShopFragment,
  useAccessPassesQuery
} from "../../graphql/api";
import { useRouter } from "../../hooks/useRouter";

export const AccessPassSelectorContainer: FC = () => {
  const { history } = useRouter();
  const { loading, data, error, fetchMore } = useAccessPassesQuery({
    variables: { first: 10 }
  });

  if (loading) {
    return <Loader title="Loading Stores..." />;
  }

  if (error) {
    return <ApolloQueryError error={error} />;
  }

  if (!data || !data.accessPassesByUser) {
    return (
      <Error
        title="Unable to load access passes"
        description="We were unable to load access passes that are normally used to verify your access to a store."
      />
    );
  }

  const hasNextPage = data.accessPassesByUser.pageInfo.hasNextPage;
  const cursor = data.accessPassesByUser.pageInfo.endCursor;
  const accessPasses = data.accessPassesByUser.edges.map(({ node }) => node);

  const handleNextPageClick = () => {
    fetchMore({
      variables: { after: cursor },
      updateQuery: (prevResult, { fetchMoreResult }): IAccessPassesQuery => {
        if (!fetchMoreResult || !fetchMoreResult.accessPassesByUser) {
          return prevResult;
        }

        const prevEdges = prevResult!.accessPassesByUser!.edges;
        const newEdges = fetchMoreResult.accessPassesByUser.edges;

        if (!newEdges.length) {
          return prevResult;
        }

        return {
          accessPassesByUser: {
            ...fetchMoreResult.accessPassesByUser,
            edges: [...prevEdges, ...newEdges]
          }
        };
      }
    });
  };

  const handleAccessPassClick = (accessPass: IAccessPassWithShopFragment) => {
    history.push(`/stores/${accessPass.shop.handle}/dashboard`);
  };

  return (
    <AccessPassSelector
      accessPasses={accessPasses}
      onClick={handleAccessPassClick}
      hasNextPage={hasNextPage}
      onNextPageClick={handleNextPageClick}
    />
  );
};
