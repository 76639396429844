import {
  IPagination,
  paginate,
  PaginateDirection,
  paginationFromParams,
  paramsFromPagination
} from "../utils/paginationHelper";
import { useRouter } from "./useRouter";

export interface IPaginatorActions {
  paginateForward: (cursor: string) => void;
  paginateBackward: (cursor: string) => void;
}

export const usePaginator = (
  fallback?: IPagination
): [IPagination, IPaginatorActions] => {
  const { location, history } = useRouter();
  const queryParams = new URLSearchParams(location.search);

  const currentPagination = paginationFromParams(
    {
      first: queryParams.get("first") || undefined,
      last: queryParams.get("last") || undefined,
      before: queryParams.get("before") || undefined,
      after: queryParams.get("after") || undefined
    },
    fallback
  );

  const doPaginate = (
    direction: PaginateDirection,
    cursor?: string | undefined
  ) => {
    const search = new URLSearchParams(location.search);
    const nextPagination = paginate(currentPagination, direction, cursor);

    history.push({
      search: paramsFromPagination(nextPagination, search).toString()
    });
  };
  const actions: IPaginatorActions = {
    paginateForward: (cursor?: string) =>
      doPaginate(PaginateDirection.forward, cursor),
    paginateBackward: (cursor?: string) =>
      doPaginate(PaginateDirection.backward, cursor)
  };

  return [currentPagination, actions];
};
