import React, { FC, ReactElement } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { MainLayout } from "./layouts/MainLayout";
import { AuthSignInPage } from "./pages/Auth/AuthSignInPage";
import { AuthStoresPage } from "./pages/Auth/AuthStoresPage";
import { DashboardPage } from "./pages/Dashboard/DashboardPage";
import { ProductResource } from "./pages/Product";

interface IProtectedRoutesProps {
  children: ReactElement;
}

const ProtectedRoutes: FC<IProtectedRoutesProps> = ({ children }) => {
  const [sessionToken] = useLocalStorage("session_token");

  if (!sessionToken) {
    return <Redirect to="/auth/sign_in" />;
  }

  return children;
};

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth/sign_in" component={AuthSignInPage} />
        <Route path="/auth/stores/select" component={AuthStoresPage} />

        <Route path="/stores/:shopHandle">
          <ProtectedRoutes>
            <MainLayout>
              <Switch>
                <Route
                  path="/stores/:shopHandle/dashboard"
                  component={DashboardPage}
                />
                <Route
                  path="/stores/:shopHandle/products"
                  component={ProductResource}
                />
              </Switch>
            </MainLayout>
          </ProtectedRoutes>
        </Route>

        {/* Catch-all */}
        <Redirect to="/auth/sign_in" />
      </Switch>
    </BrowserRouter>
  );
};
