import React, { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { shopHandleFromUrl } from "../../utils/shopHandleFromUrl";

export const StoreLink: FC<NavLinkProps> = props => {
  const linkProps = { ...props };

  if (typeof props.to === "string" && props.to.substr(0, 2) === "./") {
    const shopHandle = shopHandleFromUrl();

    linkProps.to = props.to.replace("./", `/stores/${shopHandle}/`);
  }

  return <NavLink {...linkProps} />;
};
