import React, { FC } from "react";
import { Route, Switch } from "react-router";

import { NewProductPage } from "./NewProductPage";
import { ProductListPage } from "./ProductListPage";
import { ProductPage } from "./ProductPage";

export const ProductResource: FC = () => {
  return (
    <Switch>
      <Route
        path="/stores/:shopHandle/products/new"
        component={NewProductPage}
      />
      <Route path="/stores/:shopHandle/products/:id" component={ProductPage} />
      <Route path="/stores/:shopHandle/products" component={ProductListPage} />
    </Switch>
  );
};
