import React, { FC } from "react";

import { ApolloError } from "apollo-client";
import { InternetConnectionError } from "./InternetConnectionError";
import { NetworkError } from "./NetworkError";
import { UnexpectedError } from "./UnexpectedError";

interface IProps {
  error: ApolloError;
}

export const ApolloQueryError: FC<IProps> = ({ error }) => {
  if (navigator.onLine === false) {
    return <InternetConnectionError />;
  }

  if (error.networkError) {
    return <NetworkError />;
  }

  return <UnexpectedError />;
};
