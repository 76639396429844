export default {
  graphql: {
    httpEndpoint:
      process.env.NODE_ENV === "production" && false
        ? "https://api.novi.store/graphql"
        : "https://api.novistore.test/graphql",
    wsEndpoint:
      process.env.NODE_ENV === "production" && false
        ? "wss://api.novi.store/graphql"
        : "wss://api.novistore.test/graphql"
  }
};
