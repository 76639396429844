import React, { FC, useEffect, useState } from "react";
import { SignInForm } from "../../components/Auth/SignInForm";
import { UnexpectedError } from "../../components/Error/UnexpectedError";
import {
  useCreateSessionMutation,
  useCurrentUserQuery
} from "../../graphql/api";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useRouter } from "../../hooks/useRouter";

export interface ISignInFormState {
  email: string;
  password: string;
}

export const SignInContainer: FC = () => {
  const { history } = useRouter();
  const [, setSessionToken] = useLocalStorage("session_token");
  const createSessionMutation = useCreateSessionMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [signInFormState, setSignInFormState] = useState<ISignInFormState>({
    email: "",
    password: ""
  });
  const sessionToken = useLocalStorage("session_token");
  const currentUserQuery = useCurrentUserQuery({ skip: !sessionToken });

  // If we have an existing sessionToken and it's still valid, reuse that session
  useEffect(() => {
    if (
      sessionToken !== null &&
      currentUserQuery.data &&
      currentUserQuery.data.currentUser
    ) {
      history.push("/auth/stores/select");
    }
  }, [history, sessionToken, currentUserQuery]);

  const handleSignInFormSubmit = async () => {
    const { email, password } = signInFormState;

    setIsLoading(true);

    // TODO: Handle errors
    const { data, errors } = await createSessionMutation({
      variables: {
        input: { email, password }
      }
    });

    if (errors) {
      return <UnexpectedError />;
    }

    if (data && data.createSession) {
      const { token } = data.createSession.session;

      setSessionToken(token);
      history.push("/auth/stores/select");
    }
  };

  return (
    <SignInForm
      isLoading={isLoading}
      onSubmit={handleSignInFormSubmit}
      formState={signInFormState}
      setFormState={setSignInFormState}
    />
  );
};
