import React, { FC } from "react";
import { IProductFragment } from "../../graphql/api";
import { StoreLink } from "../Common/StoreLink";

interface IProps {
  product: IProductFragment;
}

export const ProductListItem: FC<IProps> = ({ product }) => {
  return (
    <tr>
      <td>
        <StoreLink to={`./products/${product.id}`}>{product.title}</StoreLink>
      </td>
    </tr>
  );
};
