import { onError } from "apollo-link-error";
import { deleteFromStorage } from "../../hooks/useLocalStorage";

export const authErrorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions && extensions.code === "unauthenticated") {
        deleteFromStorage("session_token");
      }
    });
  }
});
