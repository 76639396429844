import styled from "styled-components";
import { StoreLink } from "../Common/StoreLink";

export const PrimaryNavigationLink = styled(StoreLink).attrs({
  activeClassName: "active"
})`
  padding: 12px;
  color: ${props => props.theme.textColors.light.secondary};

  &.active {
    color: ${props => props.theme.brandColors.primary};
  }
`;
