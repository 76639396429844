import React, { FC, MouseEvent } from "react";

import { Card } from "@blueprintjs/core";
import { IAccessPassWithShopFragment } from "../../graphql/api";

interface IProps {
  accessPass: IAccessPassWithShopFragment;
  onClick: (accessPass: IAccessPassWithShopFragment) => void;
}

export const AccessPass: FC<IProps> = ({ accessPass, onClick }) => {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    onClick(accessPass);
  };

  return (
    <Card onClick={handleClick} interactive={true}>
      {accessPass.shop.name}: {accessPass.permissions}
    </Card>
  );
};
