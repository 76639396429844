import React, { FC } from "react";

import { IAccessPassWithShopFragment } from "../../graphql/api";
import { AccessPass } from "./AccessPass";

interface IProps {
  accessPasses: IAccessPassWithShopFragment[];
  hasNextPage: boolean;
  onClick: (accessPass: IAccessPassWithShopFragment) => void;
  onNextPageClick: () => void;
}

export const AccessPassSelector: FC<IProps> = ({
  accessPasses,
  onClick: handleClick
}) => {
  return (
    <div>
      {accessPasses.map(accessPass => (
        <AccessPass
          key={accessPass.id}
          onClick={handleClick}
          accessPass={accessPass}
        />
      ))}
    </div>
  );
};
