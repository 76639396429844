import React, { FC } from "react";
import { IProductFragment } from "../../graphql/api";
import { HTMLTable } from "../Table/Table";
import { ProductListItem } from "./ProductListItem";

interface IProps {
  products: IProductFragment[];
}

export const ProductList: FC<IProps> = ({ products }) => {
  return (
    <HTMLTable>
      <tbody>
        {products.map(product => (
          <ProductListItem product={product} key={product.id} />
        ))}
      </tbody>
    </HTMLTable>
  );
};
