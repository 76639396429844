import React, { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  place-items: center;
`;

export const CenteredLayout: FC = props => {
  return <Wrapper {...props} />;
};
