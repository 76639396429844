import React, { FC, TableHTMLAttributes } from "react";
import styled from "styled-components";

const StyledTable = styled.table``;
const StyledTableRow = styled.tr``;

export const HTMLTable: FC<TableHTMLAttributes<HTMLTableElement>> = props => {
  return <StyledTable {...props} />;
};

export const HTMLTableRow: FC<
  TableHTMLAttributes<HTMLTableRowElement>
> = props => {
  return <StyledTableRow {...props} />;
};
