import React, { FC } from "react";

import styled from "styled-components";

const Container = styled.main`
  display: grid;

  grid-template-areas:
    "logo"
    "form"
    "footer";

  height: 100%;
`;

const Content = styled.div`
  grid-area: form;
  min-width: 340px;
  align-self: center;
  justify-self: center;
`;

export const AuthLayout: FC = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};
