import React, { FC } from "react";
import { IPageInfo } from "../../graphql/api";
import { IPaginatorActions } from "../../hooks/usePaginator";
import { Paginator } from "./Paginator";

interface IProps {
  actions: IPaginatorActions;
  pageInfo: IPageInfo;
}

export const PaginatedList: FC<IProps> = ({ children, actions, pageInfo }) => {
  return (
    <div>
      {children} <Paginator actions={actions} pageInfo={pageInfo} />
    </div>
  );
};
