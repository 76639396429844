import React, { FC } from "react";

import { AccessPassSelectorContainer } from "../../containers/Auth/AccessPassSelectorContainer";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { AuthLayout } from "../../layouts/AuthLayout";

export const AuthStoresPage: FC = () => {
  useDocumentTitle("Choose store");

  return (
    <AuthLayout>
      <AccessPassSelectorContainer />
    </AuthLayout>
  );
};
