import React, { FC, FormEvent } from "react";
import { IPageInfo } from "../../graphql/api";
import { IPaginatorActions } from "../../hooks/usePaginator";

interface IProps {
  actions: IPaginatorActions;
  pageInfo: IPageInfo;
}

export const Paginator: FC<IProps> = ({ actions, pageInfo }) => {
  const handlePrevClick = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (pageInfo.startCursor) {
      actions.paginateBackward(pageInfo.startCursor);
    }
  };
  const handleNextClick = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (pageInfo.endCursor) {
      actions.paginateForward(pageInfo.endCursor);
    }
  };

  return (
    <div>
      <button disabled={!pageInfo.hasPreviousPage} onClick={handlePrevClick}>
        Prev
      </button>
      <button disabled={!pageInfo.hasNextPage} onClick={handleNextClick}>
        Next
      </button>
    </div>
  );
};
