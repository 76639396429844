import { NonIdealState } from "@blueprintjs/core";
import React, { FC } from "react";
import { PaginatedList } from "../../components/Pagination/PaginatedList";
import { ProductList } from "../../components/Product/ProductList";
import { IPageInfo, IProductFragment } from "../../graphql/api";
import { IPaginatorActions } from "../../hooks/usePaginator";

interface IProps {
  products: IProductFragment[];
  actions: IPaginatorActions;
  pageInfo: IPageInfo;
}

export const ProductListContainer: FC<IProps> = ({
  products,
  pageInfo,
  actions
}) => {
  if (products.length === 0) {
    return <NonIdealState title="No products" />;
  }

  return (
    <PaginatedList actions={actions} pageInfo={pageInfo}>
      <ProductList products={products} />
    </PaginatedList>
  );
};
