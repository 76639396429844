import React, { FC } from "react";

import { Error } from "./Error";

export const UnexpectedError: FC = () => {
  return (
    <Error
      title="Something went wrong..."
      description="An unexpected error occurred, we're notified and will be checking it out. If this issue persists, please reach out to us."
    />
  );
};
