import { useEffect } from "react";

export const defaultTitleTemplate = (title: string | null) =>
  title ? `${title} – Novistore` : "Novistore";
export type ITitleTemplateFunction = typeof defaultTitleTemplate;

export const useDocumentTitle = (
  title: string,
  template: ITitleTemplateFunction = defaultTitleTemplate
) => {
  useEffect(() => {
    document.title = template(title);
  }, [template, title]);
};
