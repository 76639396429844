import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface IKeyValue<K, V> {
  key: K;
  value: V;
}

class LocalStorageChanged extends CustomEvent<IKeyValue<string, string>> {
  public static eventName = "onLocalStorageChange";

  constructor(payload: IKeyValue<string, string>) {
    super(LocalStorageChanged.eventName, { detail: payload });
  }
}

/**
 * Use this instead of directly using localStorage.setItem
 * in order to correctly send events within the same window.
 *
 * @example
 * ```js
 * writeStorage('hello', JSON.stringify({ name: 'world' }));
 * const { name } = JSON.parse(localStorage.getItem('hello'));
 * ```
 *
 * @export
 * @param {string} key The key to write to in the localStorage.
 * @param {string} value The value to write to in the localStorage.
 */
export function writeStorage(key: string, value: string) {
  localStorage.setItem(key, value);
  window.dispatchEvent(new LocalStorageChanged({ key, value }));
}

/**
 * Use this function to delete a value from localStorage.
 *
 * @example
 * ```js
 * const user = { name: 'John', email: 'John@fakemail.com' };
 *
 * // Add a user to your localStorage
 * writeStorage('user', JSON.stringify(user));
 *
 * // This will also trigger an update to the state of your component
 * deleteFromStorage('user');
 * ```
 *
 * @export
 * @param {string} key The key of the item you wish to delete from localStorage.
 */
export function deleteFromStorage(key: string) {
  localStorage.removeItem(key);
  window.dispatchEvent(new LocalStorageChanged({ key, value: "" }));
}

function onLocalStorageChange(
  key: string,
  stateUpdater: Dispatch<SetStateAction<string | null>>,
  event: LocalStorageChanged | StorageEvent
) {
  if (event instanceof LocalStorageChanged) {
    if (event.detail.key === key) {
      stateUpdater(event.detail.value);
    }
  } else {
    if (event.key === key) {
      stateUpdater(event.newValue);
    }
  }
}

/**
 * React hook to enable updates to state via localStorage.
 * This updates when the {writeStorage} function is used, when the returned function
 * is called, or when the "storage" event is fired from another tab in the browser.
 *
 * @example
 * ```js
 * const MyComponent = () => {
 *   const [myStoredItem, setMyStoredItem] = useLocalStorage('myStoredItem');
 *   return (
 *     <p>{myStoredItem}</p>
 *   );
 * };
 * ```
 *
 * @export
 * @param {string} key The key in the localStorage that you wish to watch.
 * @returns An array containing the value associated with the key in position 0,
 * and a function to set the value in position 1.
 */
export function useLocalStorage(
  key: string
): [string | null, Dispatch<string>, Dispatch<void>] {
  const [localState, updateLocalState] = useState(localStorage.getItem(key));

  useEffect(() => {
    const localEventListener = (event: any) =>
      onLocalStorageChange(key, updateLocalState, event as LocalStorageChanged);
    const remoteEventListener = (event: StorageEvent) =>
      onLocalStorageChange(key, updateLocalState, event);

    window.addEventListener(LocalStorageChanged.eventName, localEventListener);
    window.addEventListener("storage", remoteEventListener);

    return () => {
      window.removeEventListener(
        LocalStorageChanged.eventName,
        localEventListener
      );
      window.removeEventListener("storage", remoteEventListener);
    };
  }, [key]);

  return [
    localState,
    (value: string) => writeStorage(key, value),
    () => deleteFromStorage(key)
  ];
}
